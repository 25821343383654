import * as React from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Header from "../components/atoms/Header";
import Steps from "../components/organisms/Steps";
import Products from "../components/organisms/Products";
import Testimonials from "../components/organisms/Testimonials";
import Solutions from "../components/organisms/Solutions";
import Faqs from "../components/organisms/Faqs";
import Features from "../components/organisms/Features";
import Partners from "../components/organisms/Partners";
import Footer from "../components/organisms/Footer";

const sortImages = (first, second) => {
  const f = first.node.original.src.substring(first.node.original.src.lastIndexOf("/"));
  const s = second.node.original.src.substring(second.node.original.src.lastIndexOf("/"));
  return f < s ? -1 : 1;
};

const filterAndSortImages = (images, keyword) =>
  images.filter((i) => i.node.original.src.includes(keyword)).sort(sortImages);

const MainPage = ({ data }) => {
  const { brand, website, products, solutions, steps, features, partners, faqs, testimonials, images } = data;
  const { layout } = website.live;

  const hasContent =
    products[0] || solutions[0] || steps[0] || features[0] || partners[0] || faqs[0] || testimonials[0];

  return (
    <>
      <Header brand={brand} website={website} coverImage={images.find((i) => i.node.original.src.includes("coverImage"))} />
      <Stack justifyContent={"center"} pt={5} sx={{ backgroundColor: "background.main", color: "primary.main" }}>
        <Container>
          <Stack spacing={5} pb={5}>
            <Products products={products} layout={layout} images={filterAndSortImages(images, "products")} />
            <Solutions solutions={solutions} layout={layout} images={filterAndSortImages(images, "solutions")} />
            <Partners partners={partners} layout={layout} images={filterAndSortImages(images, "partners")} />
            <Steps steps={steps} layout={layout} images={filterAndSortImages(images, "steps")} />
            <Features features={features} layout={layout} images={filterAndSortImages(images, "features")} />
            <Faqs faqs={faqs} layout={layout} />
            <Testimonials testimonials={testimonials} layout={layout} images={filterAndSortImages(images, "testimonials")} />
          </Stack>
        </Container>
        {hasContent ? (
          <Footer brand={brand} website={website} />
        ) : (
          <Container>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="caption" component="p">
                {brand.name} © 2022
              </Typography>
              <Typography variant={"subtitle1"}>
                Made with{" "}
                <Link
                  href={"https://tryevergrow.com"}
                  target="_blank"
                  rel="noopener"
                  fontWeight="bold"
                  underline="none"
                  color={"inherit"}
                >
                  Evergrow
                </Link>
              </Typography>
            </Stack>
          </Container>
        )}
      </Stack>
    </>
  );
};

export default MainPage;
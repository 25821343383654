import * as React from "react";
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

declare global {
  interface Window {
    gtag_report_conversion: Function | undefined;
  }
}

const initialValues = {
  firstName: "",
  lastName: "",
  phone: "",
  location: "",
  email: "",
  form: ""
};

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format").required("Required"),
  form: Yup.string(),
});

const FormModal = ({ open, siteId, formDefinition, layout, closeModal }) => {
  const [openModal2, setOpenModal2] = useState<boolean>(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "90vw",
      md: "90vw",
    },
    maxWidth: 400,
    bgcolor: "white",
    borderRadius: layout.buttonShape === "squared" ? 0 : 4,
    p: 4,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-describedby="modal-modal-description"
      >
        <Stack sx={style} id="modal-modal-description" spacing={2}>
          <Typography variant="h5" color="primary">
            {formDefinition.title}
          </Typography>
          <LeadForm
            data={{ siteId, formDefinition, layout }}
            closeModal={closeModal}
            setOpenModal2={setOpenModal2}
          />
        </Stack>
      </Modal>
      <Modal
        open={openModal2}
        aria-describedby="modal-modal-description"
        onClose={() => {
          setOpenModal2(false);
        }}
      >
        <Stack sx={style} id="modal-modal-description" spacing={2}>
          <Typography variant="h5" color="primary">
            Your message has been sent!
          </Typography>
        </Stack>
      </Modal>
    </>
  );
};

function LeadForm({ data, closeModal, setOpenModal2 }) {
  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    setCurrentUrl(window.location.href); // Set the URL using state
  }, []);
  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await fetch(process.env.GATSBY_LEAD_URL as string, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.GATSBY_LEAD_API_KEY!,
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          siteId: data.siteId,
          firstName: values.firstName,
          lastName: values.lastName,
          location: values.location,
          phone: values.phone,
          fromEmail: values.email,
          form: values.form,
          url: currentUrl
        }),
      });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "gtm.formSubmit",
        formId: "evergrowForm",
      });

      //if (typeof window.fbq === "function") {
      //  window.fbq("track", "Lead");
      // }

      if (window.gtag_report_conversion) {
        window.gtag_report_conversion();
        console.log("submitted gtag_report_conversion");
      } else {
        console.log("No gtag_report_conversion");
      }
    } finally {
      setSubmitting(false);
      closeModal();
      setOpenModal2(true);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, isValid, dirty }) => (
        <Form>
          <Stack spacing={2}>
            {data.formDefinition.firstNameEnabled && (
              <Field name={"firstName"}>
                {(props) => {
                  const { field, form, meta } = props;
                  return (
                    <TextField
                      id={"firstName"}
                      label={
                        data.formDefinition.lastNameEnabled
                          ? "First name"
                          : "Name"
                      }
                      {...field}
                      helperText={
                        meta.touched && meta.error ? meta.error : null
                      }
                      error={meta.touched && meta.error ? true : false}
                      sx={{ flexGrow: 1 }}
                    />
                  );
                }}
              </Field>
            )}
            {data.formDefinition.lastNameEnabled && (
              <Field name={"lastName"}>
                {(props) => {
                  const { field, form, meta } = props;
                  return (
                    <TextField
                      id={"lastName"}
                      label={"Last name"}
                      {...field}
                      helperText={
                        meta.touched && meta.error ? meta.error : null
                      }
                      error={meta.touched && meta.error ? true : false}
                      sx={{ flexGrow: 1 }}
                    />
                  );
                }}
              </Field>
            )}
            {data.formDefinition.phoneEnabled && (
              <Field name={"phone"}>
                {(props) => {
                  const { field, form, meta } = props;
                  return (
                    <TextField
                      id={"phone"}
                      label={"Phone"}
                      {...field}
                      helperText={
                        meta.touched && meta.error ? meta.error : null
                      }
                      error={meta.touched && meta.error ? true : false}
                      sx={{ flexGrow: 1 }}
                    />
                  );
                }}
              </Field>
            )}
            {data.formDefinition.locationEnabled && (
              <Field name={"location"}>
                {(props) => {
                  const { field, form, meta } = props;
                  return (
                    <TextField
                      id={"location"}
                      label={
                        data.formDefinition.locationLabel
                          ? data.formDefinition.locationLabel
                          : "Location"
                      }
                      {...field}
                      helperText={
                        meta.touched && meta.error ? meta.error : null
                      }
                      error={meta.touched && meta.error ? true : false}
                      sx={{ flexGrow: 1 }}
                    />
                  );
                }}
              </Field>
            )}
            {data.formDefinition.emailHeaderEnabled && (
              <Field name={"email"}>
                {(props) => {
                  const { field, form, meta } = props;
                  return (
                    <TextField
                      id={"email"}
                      label={
                        data.formDefinition.emailHeader
                          ? data.formDefinition.emailHeader
                          : "Email"
                      }
                      {...field}
                      helperText={
                        meta.touched && meta.error ? meta.error : null
                      }
                      error={meta.touched && meta.error ? true : false}
                      sx={{ flexGrow: 1 }}
                    />
                  );
                }}
              </Field>
            )}
            {data.formDefinition.formHeaderEnabled && (
              <Field name={"form"}>
                {(props) => {
                  const { field, form, meta } = props;
                  return (
                    <TextField
                      id={"form"}
                      label={
                        data.formDefinition.formHeader
                          ? data.formDefinition.formHeader
                          : "Message"
                      }
                      {...field}
                      helperText={
                        meta.touched && meta.error ? meta.error : null
                      }
                      error={meta.touched && meta.error ? true : false}
                      rows={2}
                      multiline
                      sx={{ flexGrow: 1 }}
                    />
                  );
                }}
              </Field>
            )}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || !isValid || !dirty}
              sx={{
                borderRadius:
                  data.layout.buttonShape === "pill"
                    ? 10
                    : data.layout.buttonShape === "rounded"
                    ? 2
                    : 0,
                marginTop: "0",
                padding: "12px 12px 12px 12px",
                fontWeight: "bold",
              }}
              size="large"
            >
              {isSubmitting ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

export default FormModal;

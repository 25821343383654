import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LargeTile from "../atoms/LargeTile";
import SmallTile from "../atoms/SmallTile";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const Products = ({ products, layout, images }) => {
  const items = products?.length;
  const { density, sectionTitles } = layout;
  const gridSize = items === 1 ? 12 : items === 2 ? 6 : 4;

  if (items === 0) return null;

  const renderSmallTiles = () =>
    products.map((product, index) => (
      <SmallTile
        key={"product-" + index}
        name={product.name}
        description={product.description}
        price={product.price}
        image={images[index]?.node?.fluid?.src}
        itemSize={gridSize}
        border={layout.buttonShape}
      />
    ));

  const renderLargeTiles = () =>
    products.map((product, index) => (
      <LargeTile
        key={"product-" + index}
        name={product.name}
        description={product.description}
        price={product.price}
        image={images[index]?.node?.fluid?.src}
        border={layout.buttonShape}
      />
    ));

  return (
    <>
      <Stack textAlign="center" py={4}>
        {sectionTitles && (
          <Typography variant="h2" color="primary.light">
            Products
          </Typography>
        )}
      </Stack>
      {density === "compact" && items > 1 ? (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3} display="flex">
            {renderSmallTiles()}
          </Grid>
        </Box>
      ) : (
        renderLargeTiles()
      )}
    </>
  );
};

export default Products;
